@import "../assets/fonts/DKV/style.scss";

@font-face {
  font-family: "FrutigerNextLTW05";
  src: url("/assets/fonts/FrutigerNextLTW05/FrutigerNextLTW05-Regular.woff2") format("woff2"),
    url("/assets/fonts/FrutigerNextLTW05/FrutigerNextLTW05-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FrutigerNextLTW05";
  src: url("/assets/fonts/FrutigerNextLTW05/FrutigerNextLTW05-Italic.woff2") format("woff2"),
    url("/assets/fonts/FrutigerNextLTW05/FrutigerNextLTW05-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "FrutigerNextLTW05";
  src: url("/assets/fonts/FrutigerNextLTW05/FrutigerNextLTW05-Medium.woff2") format("woff2"),
    url("/assets/fonts/FrutigerNextLTW05/FrutigerNextLTW05-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FrutigerNextLTW05";
  src: url("/assets/fonts/FrutigerNextLTW05/FrutigerNextLTW05-Bold.woff2") format("woff2"),
    url("/assets/fonts/FrutigerNextLTW05/FrutigerNextLTW05-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
