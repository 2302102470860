$icomoon-font-family: "DKV" !default;
$icomoon-font-path: "fonts" !default;

$icon-ico_toiletdisposal: "\e9ce";
$icon-ico_addressbook: "\e9c4";
$icon-ico_document-search: "\e9cb";
$icon-ico_folder-closed: "\e9cc";
$icon-ico_folder-opened: "\e9cd";
$icon-ico_download: "\e931";
$icon-ico_return-undo: "\e988";
$icon-ico_shower: "\e9ca";
$icon-ico_zoom-out: "\e9c3";
$icon-ico_truck: "\e99a";
$icon-ico_truck-small-active: "\e971";
$icon-ico_truck-lessload: "\e980";
$icon-ico_truck-active: "\e999";
$icon-ico_export: "\e934";
$icon-ico_drivers: "\e90f";
$icon-ico_walker: "\e9c5";
$icon-ico_scooter: "\e9c6";
$icon-ico_import: "\e9c7";
$icon-ico_boat: "\e9c8";
$icon-ico_bike: "\e9c9";
$icon-ico_profil-active: "\e9bd";
$icon-ico_abstract-filterfavorit: "\e9be";
$icon-ico_instructions: "\e9bf";
$icon-ico_compass: "\e9c0";
$icon-ico_tacho-active: "\e9c1";
$icon-ico_activity: "\e9c2";
$icon-ico_profil: "\e920";
$icon-ico_tacho: "\e94b";
$icon-ico_webcam: "\e98e";
$icon-ico_petrolmeter: "\e9a3";
$icon-ico_petrol: "\e9a8";
$icon-ico_parking-lkw: "\e9a9";
$icon-ico_parking-garage: "\e9aa";
$icon-ico_motorway: "\e9ab";
$icon-ico_motorway-closeby: "\e9ac";
$icon-ico_megaphone: "\e9ad";
$icon-ico_list: "\e9ae";
$icon-ico_label: "\e9af";
$icon-ico_high-performance-fuel: "\e9b0";
$icon-ico_cooling: "\e9b1";
$icon-ico_carwash-lkw: "\e9b2";
$icon-ico_carwash-car: "\e9b3";
$icon-ico_car-front: "\e9b4";
$icon-ico_bistro: "\e9b5";
$icon-ico_automatic-fuel: "\e9b6";
$icon-ico_truck-trailer: "\e9b7";
$icon-ico_truck-base: "\e9b8";
$icon-ico_toilet: "\e9b9";
$icon-ico_tire: "\e9ba";
$icon-ico_timeinterval: "\e9bb";
$icon-ico_tachograph: "\e9bc";
$icon-ico_restaurant: "\e948";
$icon-ico_cal-week: "\e957";
$icon-ico_car-rental: "\e996";
$icon-ico_carwash: "\e960";
$icon-ico_bathroom: "\e958";
$icon-ico_search: "\e91d";
$icon-ico_cal: "\e903";
$icon-ico_fuel: "\e942";
$icon-ico_globus: "\e935";
$icon-ico_fleet: "\e912";
$icon-ico_notifications: "\e91f";
$icon-ico_settings: "\e913";
$icon-ico_route: "\e97d";
$icon-ico_plug: "\e95f";
$icon-ico_car: "\e970";
$icon-ico_tools-active: "\e972";
$icon-ico_tools: "\e973";
$icon-ico_fuel-active: "\e97b";
$icon-ico_transport-chain: "\e97f";
$icon-ico_geozone-dot: "\e981";
$icon-ico_document-new: "\e982";
$icon-ico_signal-missing: "\e983";
$icon-ico_signal: "\e984";
$icon-ico_arrow-down: "\e985";
$icon-ico_arrow-top: "\e986";
$icon-ico_arrows: "\e987";
$icon-ico_scale: "\e989";
$icon-ico_unread: "\e98a";
$icon-ico_abstract-filter: "\e98b";
$icon-ico_geozone: "\e98c";
$icon-ico_tank: "\e98d";
$icon-ico_zoom-in: "\e98f";
$icon-ico_network: "\e990";
$icon-ico_position: "\e991";
$icon-ico_server: "\e992";
$icon-ico_trail: "\e993";
$icon-ico_globus-active: "\e994";
$icon-ico_search-active: "\e995";
$icon-ico_superuser-active: "\e997";
$icon-ico_superuser: "\e998";
$icon-ico_fleet-active: "\e99b";
$icon-ico_notifications-active: "\e99c";
$icon-ico_settings-active: "\e99d";
$icon-ico_help-active: "\e99e";
$icon-ico_help: "\e9a1";
$icon-ico_driver-active: "\e99f";
$icon-ico_driver: "\e9a0";
$icon-ico_route-active: "\e9a2";
$icon-ico_dashboard-active: "\e9a4";
$icon-ico_dashboard: "\e9a5";
$icon-ico_reporting-active: "\e9a6";
$icon-ico_reporting: "\e9a7";
$icon-ico_remove: "\e90c";
$icon-ico_add: "\e90d";
$icon-ico_warning: "\e90e";
$icon-ico_billings: "\e910";
$icon-ico_services: "\e911";
$icon-ico_routes: "\e914";
$icon-ico_up: "\e915";
$icon-ico_down: "\e916";
$icon-ico_product-config: "\e917";
$icon-ico_address: "\e918";
$icon-ico_check: "\e919";
$icon-ico_trend: "\e91a";
$icon-ico_trend_up: "\e91b";
$icon-ico_trend_down: "\e91c";
$icon-ico_plus: "\e91e";
$icon-ico_status-up: "\e921";
$icon-ico_status_down: "\e922";
$icon-ico_logout: "\e923";
$icon-ico_next: "\e924";
$icon-ico_burger: "\e925";
$icon-ico_camera: "\e926";
$icon-ico_check_status_empty: "\e927";
$icon-ico_check_status: "\e928";
$icon-ico_check-Kopie: "\e929";
$icon-ico_clear: "\e92a";
$icon-ico_delete: "\e92b";
$icon-ico_details: "\e92c";
$icon-ico_watching: "\e92d";
$icon-ico_doc_send: "\e92e";
$icon-ico_document: "\e92f";
$icon-ico_doc_sign: "\e930";
$icon-ico_download1: "\e932";
$icon-ico_edit: "\e933";
$icon-ico_info: "\e936";
$icon-ico_fast_track: "\e937";
$icon-ico_load: "\e938";
$icon-ico_back: "\e900";
$icon-ico_exclamation: "\e901";
$icon-ico_maintenance: "\e902";
$icon-ico_plugin: "\e904";
$icon-ico_upload: "\e905";
$icon-ico_alert: "\e906";
$icon-ico_webshop: "\e907";
$icon-ico_xing: "\e908";
$icon-ico_linked-in: "\e909";
$icon-ico_facebook: "\e90a";
$icon-ico_twitter: "\e90b";
$icon-ico_tunnel: "\e939";
$icon-ico_break: "\e93a";
$icon-ico_clock: "\e93b";
$icon-ico_chevron-down: "\e93d";
$icon-ico_chevron-up: "\e93e";
$icon-ico_chevron-left: "\e93f";
$icon-ico_chevron-right: "\e940";
$icon-ico_filter: "\e941";
$icon-ico_lock: "\e943";
$icon-ico_optional-menu: "\e944";
$icon-ico_pokal: "\e945";
$icon-ico_ranglist: "\e946";
$icon-ico_ranking: "\e947";
$icon-ico_settings-2: "\e949";
$icon-ico_star: "\e94a";
$icon-ico_thumb-down: "\e97e";
$icon-ico_truck24: "\e94d";
$icon-ico_print: "\e959";
$icon-ico_overview: "\e95a";
$icon-ico_co2: "\e95b";
$icon-ico_phone: "\e95c";
$icon-ico_vignette: "\e95d";
$icon-ico_csv: "\e95e";
$icon-ico_jpg: "\e961";
$icon-ico_jpeg: "\e964";
$icon-ico_pdf: "\e967";
$icon-ico_xls: "\e96a";
$icon-ico_mail: "\e96d";
$icon-ico_toll: "\e96e";
$icon-ico_tollbox: "\e96f";
$icon-ico_creditcard: "\e94e";
$icon-ico_ThumbU: "\e97c";
$icon-ico_ThumbUoutline: "\e94f";
$icon-ico_ThumbDoutline: "\e950";
$icon-ico_customer: "\e951";
$icon-ico_ChevronSLeft: "\e962";
$icon-ico_ChevronSRight: "\e963";
$icon-ico_direction11: "\e965";
$icon-ico_direction12: "\e966";
$icon-ico_direction13: "\e968";
$icon-ico_direction14: "\e969";
$icon-ico_direction15: "\e96b";
$icon-ico_direction21: "\e96c";
$icon-ico_direction22: "\e974";
$icon-ico_direction23: "\e975";
$icon-ico_direction24: "\e976";
$icon-ico_direction25: "\e977";
$icon-ico_swapHR: "\e978";
$icon-ico_swapVR: "\e979";
$icon-ico_minus: "\e93c";
$icon-ico_unlock: "\e97a";
$icon-ico_warning_full: "\e94c";
$icon-ico_return: "\e952";
$icon-ico_star_full: "\e953";
$icon-ico_parking: "\e954";
$icon-ico_info_full: "\e955";
$icon-Ico_info_outline: "\e956";
