:root {
  --dkv-background-primary-color: #ffffff;
  --dkv-background-primary-hover-color: #efefef;
  --dkv-background-secondary-color: #efefef;

  --dkv-highlight-primary-color: #ee8500;
  --dkv-highlight-primary-hover-dark-color: #{darken(#ee8500, 5%)};
  --dkv-highlight-primary-text-color: #fafafa;

  --dkv-highlight-error-color: #e44c4d;
  --dkv-highlight-warning-color: #fbc02d;
  --dkv-highlight-info-color: #809fb7;
  --dkv-highlight-ok-color: #58be5b;

  --dkv-page-headline-color: #323232;

  --dkv-text-primary-color: #353535;
  --dkv-text-primary-hover-color: #{darken(#353535, 10%)};
  --dkv-text-caption-color: #a5a4a5;

  --dkv-link-primary-color: #004b78;
  --dkv-link-primary-hover-color: #{darken(#004b78, 15%)};

  --dkv-nav-primary-color: #666666;

  --dkv-scrollbar-color: #a5a4a5;
  --dkv-scrollbar-hover-color: #{darken(#a5a4a5, 10%)};

  --dkv-text-black-color: #323232;

  --dkv-driving-timeline-drive: #004b78;
  --dkv-driving-timeline-break: #fabf2d;
  --dkv-driving-timeline-extension: #ffffff;
  --dkv-driving-timeline-extension-border: #666666;

  --dkv-stepper-inactive-color: #dbdbdb;
  --dkv-stepper-active-color: #f18400;
  --dkv-stepper-completed-color: #58be5b;

  // ----------------------------------
  --dkv-dkv_blue: #004b78;
  --dkv-dkv_blue_20: #ccdbe4;
  --dkv-dkv_blue_hover: #2e6b90;
  --dkv-dkv_blue_pressed: #003e63;
  --dkv-dkv_orange: #f18400;
  --dkv-dkv_orange_hover: #f39a2e;
  --dkv-dkv_orange_pressed: #c66d00;

  --dkv-grey_20: #efefef;
  --dkv-grey_30: #dbdbdb;
  --dkv-grey_40: #c1c1c1;
  --dkv-grey_50: #989898;
  --dkv-grey_70: #666666;
  --dkv-grey_90: #323232;
  --dkv-white: #ffffff;

  --dkv-carminepink: #e44c4d;
  --dkv-carminepink_20: #fadbdb;
  --dkv-mantis: #58be58;
  --dkv-mantis_20: #def2de;
  --dkv-saffron: #fbc02d;
  --dkv-saffron_20: #fef2d5;
}
