@import-normalize;
@import "_variables";
@import "_fonts";
@import "_scrollbar";

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

[hidden] {
  display: none !important;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
p {
  margin: 0;
  font-weight: normal;
}

a {
  text-decoration: none;
  color: var(--dkv-link-primary-color);
}

.dkv-display-flex {
  display: flex;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  -webkit-text-fill-color: var(--dkv-ui-input-color, #666666) !important;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child {
  stroke-opacity: 0;
}

.recharts-cartesian-axis-tick-value,
.recharts-reference-label {
  font-size: 13px;
}

.MuiPopover-root {
  z-index: 10001 !important;
}

.MuiMenuItem-root {
  font-family: "FrutigerNextLTW05", sans-serif !important;
  font-size: 1.1428571428571428rem !important;
}

.MuiTooltip-tooltip {
  font-family: "FrutigerNextLTW05", sans-serif !important;
  font-size: 14px !important;
  background-color: #666666 !important;
}

.dkv-dtco-tooltip {
  z-index: 5001 !important;

  .MuiTooltip-tooltip {
    background-color: #fff !important;
    border-radius: 2px;
    border: solid 1px #dbdbdb;
    color: #8c8c8c !important;
    font-weight: normal !important;
    font-size: 12px !important;
  }
}

.MuiAutocomplete-popper,
.MuiTooltip-popper {
  z-index: 10001 !important;
}

.dkv-filter-popover {
  border-radius: 0 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3) !important;
  padding: 12px;
  width: 370px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-left: 0 !important;
  padding-top: 7px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  font-size: 1.1428571428571428rem !important;
  padding: 8px 16px !important;
}

.MuiAutocomplete-option {
  font-family: "FrutigerNextLTW05", sans-serif !important;
  font-size: 1.1428571428571428rem !important;
  color: #666 !important;
}

.MuiAutocomplete-paper {
  border-radius: 2px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5) !important;
  border: 1px solid var(--dkv-ui-input-border-color, #dbdbdb) !important;
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: #e9f3f9 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--dkv-ui-input-hover-border-color, #666666) !important;
}

.MuiSelect-select:focus {
  background-color: unset !important;
}

.MuiInputBase-root {
  font-family: "FrutigerNextLTW05", sans-serif !important;
}

.MuiTypography-root {
  font-family: "FrutigerNextLTW05", sans-serif !important;
}

.dkv-datetimepicker {
  font-family: "FrutigerNextLTW05", sans-serif !important;

  .MuiPaper-root {
    box-shadow: 0 10px 20px 0 rgba(128, 128, 128, 0.2);
    border-radius: 2px;
    border: solid 1px #c1c1c1;
  }

  .MuiPickersDay-daySelected {
    background-color: #004b78;
  }

  .MuiPickersDay-daySelected:hover {
    background-color: #003e63;
  }

  .MuiPickersClockPointer-pointer,
  .MuiPickersClockNumber-clockNumberSelected,
  .MuiPickersClock-pin,
  .MuiPickersClockPointer-thumb {
    background-color: #004b78;
  }

  .MuiPickersClockPointer-thumb {
    border: 14px solid #003e63;
  }

  .MuiPickersClock-clock {
    background-color: #efefef;
  }
}

.MuiSwitch-root {
  height: 42px !important;

  .MuiSwitch-thumb {
    width: 14px;
    height: 14px;
  }

  .MuiSwitch-switchBase {
    top: 5px;
    left: 6px;

    &.Mui-checked {
      transform: translateX(14px) !important;

      + .MuiSwitch-track {
        background-color: var(--dkv-dkv_blue);
        opacity: 1;
      }
    }
  }

  .MuiSwitch-track {
    border-radius: 25px;
  }
}

.MuiSwitch-root.MuiSwitch-sizeSmall {
  height: 22px !important;
  padding: 2px 4px;

  .MuiSwitch-thumb {
    width: 12px;
    height: 12px;
  }

  .MuiSwitch-switchBase {
    top: 1px;
    left: 3px;

    &.Mui-checked {
      transform: translateX(14px);

      + .MuiSwitch-track {
        background-color: var(--dkv-dkv_blue);
        opacity: 1;
      }
    }
  }

  .MuiSwitch-track {
    border-radius: 25px;
  }
}

.dkv-select-checkbox-menuitem {
  background-color: transparent !important;

  .p-checkbox {
    margin-right: 12px;
  }
}

.p-growl-item-container {
  background-color: #004b78;
  color: #fff;
  font-family: inherit !important;

  .p-growl-image {
    display: none;
  }

  .p-growl-message {
    margin-left: 0;
  }
}

.p-growl-message-success {
  background-color: #58be58;
}

.p-growl-message-error {
  background-color: #e44c4d;
}
