html {
  scrollbar-color: var(--dkv-scrollbar-color, #a5a5a5) transparent;

  * {
    scrollbar-width: thin;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 10px;
  background-color: var(--dkv-scrollbar-color, #a5a5a5);
  border: 2px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--dkv-scrollbar-hover-color, #a5a5a5);
  background-clip: padding-box;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
